import vars from "@/main_variablen.js"; // oder relativer Pfad

export default (value) => {
  if (vars.private) {
    value = 0;
  }
  const number = parseFloat(value);
  let formattedValue = number.toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formattedValue + " €";
};
