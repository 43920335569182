// ****************************************************************
// Definition globale Variablen
// ****************************************************************

import Vue from "vue";

const vars = Vue.observable({
  snackbar: {
    show: false,
    text: "",
  },
  private: false,
});

export default vars;
