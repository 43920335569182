<template>
  <v-container>
    <v-navigation-drawer
      permanent
      app
      clipped
      width="370px"
      class="hidden-sm-and-down"
    >
      <v-sheet class="ma-3">
        <v-card-title class="pb-0 text-center">
          <v-btn text block to="/rechnungen"
            >Rechnungen<br />{{
              rechnungen.reduce((summe, rechnung) => summe + rechnung.summe, 0)
                | money
            }}<v-icon>keyboard_arrow_right</v-icon></v-btn
          >
        </v-card-title>
        <v-card
          v-if="rechnungen.length > 0"
          flat
          outlined
          class="rounded-lg mb-2"
        >
          <v-list dense class="py-0">
            <span v-for="(item, index) in rechnungen" :key="index">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ item.betreff }}</v-list-item-title>
                  <v-list-item-subtitle
                    >{{ item.kunde | user(kunden) }} |
                    {{
                      item.rechnungsdatum | date("DDMMYYYY")
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip label small>{{ item.summe | money }}</v-chip>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="index < rechnungen.length - 1"></v-divider>
            </span>
          </v-list>
        </v-card>
        <v-card-title class="pb-0">
          <v-btn text block to="/angebote"
            >Angebote<v-icon>keyboard_arrow_right</v-icon></v-btn
          >
        </v-card-title>
        <v-card v-if="angebote.length > 0" outlined class="rounded-lg mb-2">
          <v-list dense class="py-0">
            <span v-for="(item, index) in angebote" :key="index">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ item.betreff }}</v-list-item-title>
                  <v-list-item-subtitle
                    >{{ item.kunde | user(kunden) }} |
                    {{
                      item.angebotsdatum | date("DDMMYYYY")
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip label small>{{ item.summe | money }}</v-chip>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="index < angebote.length - 1"></v-divider>
            </span>
          </v-list>
        </v-card>
        <v-card-title class="pb-0">
          <v-btn text block to="/projekte"
            >Projekte<v-icon>keyboard_arrow_right</v-icon></v-btn
          >
        </v-card-title>
        <v-card outlined class="rounded-lg">
          <v-list dense class="py-0">
            <span v-for="(item, index) in projekte" :key="index">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ item.titel }}</v-list-item-title>
                  <v-list-item-subtitle
                    >{{ item.kunde | user(kunden) }} |
                    {{ item.start | date("DDMMYYYY") }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="index < projekte.length - 1"></v-divider>
            </span>
          </v-list>
        </v-card>
      </v-sheet>
    </v-navigation-drawer>

    <v-row class="ma-2">
      <v-col cols="12" lg="8">
        <v-card outlined class="rounded-lg">
          <v-card-title class="py-1">
            <v-spacer></v-spacer>
            <v-btn icon @click="addJahr(-1)"
              ><v-icon>keyboard_arrow_left</v-icon></v-btn
            >
            <v-btn @click="addJahr(0)" outlined>{{ jahr }}</v-btn>
            <v-btn icon @click="addJahr(1)"
              ><v-icon>keyboard_arrow_right</v-icon></v-btn
            >
            <v-spacer></v-spacer>
          </v-card-title>
        </v-card>
        <v-card outlined class="rounded-lg mt-2">
          <v-card-title class="py-1 px-3 d-flex justify-space-between">
            Einnahmen
            <v-chip label small class="green white--text hidden-sm-and-down"
              >{{ einnahmenMonat.sum | money }} | &Oslash;
              {{ (einnahmenMonat.sum / 12).toFixed(1) | money }}</v-chip
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-progress-linear
              :value="(item / Math.max(...einnahmenMonat.data[0].data)) * 100"
              :buffer-value="
                (einnahmenMonat.sum /
                  12 /
                  Math.max(...einnahmenMonat.data[0].data)) *
                100
              "
              height="24"
              class="mb-1"
              color="blue"
              rounded
              v-for="(item, index) in einnahmenMonat.data[0].data"
              :key="index"
            >
              <v-row no-gutters class="mx-2">
                <v-col cols="6" class="text-truncate subtitle-2 text-left"
                  >{{
                    new Date(Date.UTC(2023, index, 1)).toLocaleString("de-DE", {
                      month: "short",
                    })
                  }}.</v-col
                >
                <v-col cols="6" class="subtitle-2 text-right">{{
                  item | money
                }}</v-col>
              </v-row>
            </v-progress-linear>
          </v-card-text>
        </v-card>
        <!-- {{ heatmapData }} -->
        <v-card outlined class="rounded-lg mt-2">
          <v-card-title class="py-1 px-3 d-flex justify-space-between">
            Arbeitsstunden
            <v-chip label small class="green white--text hidden-sm-and-down">
              {{ heatmapData.sum }} h | {{ heatmapData.days }} Tage | &Oslash;
              {{ (heatmapData.sum / currentWeek).toFixed(1) }} h/Woche
            </v-chip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row class="pa-0">
              <v-col
                cols="12"
                class="px-1 py-1 d-flex align-center justify-left"
                v-for="(monat, monatIndex) in heatmapData.series"
                :key="monatIndex"
              >
                <v-sheet
                  class="d-flex align-center justify-center"
                  width="40px"
                  height="20px"
                >
                  {{ monat.name }}
                </v-sheet>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-sheet
                      width="calc(((100% - 60px - (42px))*3/34))"
                      class="d-flex align-center justify-end pr-2 grey lighten-5"
                      height="20px"
                      style="margin-right: 2px"
                      v-on="on"
                      v-bind="attrs"
                      rounded
                    >
                      {{ monat.sumRound }} h
                    </v-sheet>
                  </template>
                  <span>
                    Gesamtstunden: {{ monat.sum }} h <br />Stunden / Tag:
                    {{ (monat.sum / monat.data.length).toFixed(1) }} h
                    <br />gearbeitete Tage:
                    {{ monat.data.filter((value) => value !== 0).length }} /
                    {{ monat.data.length }} Tage <br />Stunden / gearbeiteter
                    Tag:
                    {{
                      monat.data.filter((value) => value !== 0).length > 0
                        ? (
                            monat.sum /
                            monat.data.filter((value) => value !== 0).length
                          ).toFixed(1)
                        : "-"
                    }}
                    h
                  </span>
                </v-tooltip>
                <v-tooltip
                  bottom
                  v-for="(data, dataIndex) in monat.data"
                  :key="dataIndex"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-sheet
                      width="calc(((100% - 60px - (42px))/34))"
                      height="20px"
                      style="margin-right: 2px"
                      :class="getColor(data)"
                      v-on="on"
                      v-bind="attrs"
                      rounded
                    >
                    </v-sheet>
                  </template>
                  <span>
                    {{ dataIndex + 1 }}.{{ monatIndex + 1 }}. |
                    {{ data.toFixed(2).replace(".", ",") }} h</span
                  >
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row>
          <v-col cols="6">
            <v-card-title class="pb-0 pt-5">
              <v-btn text block>Zeit pro Kunden</v-btn>
            </v-card-title>
            <v-card outlined class="rounded-lg">
              <v-progress-linear
                v-for="(item, index) in zeitProKunde"
                :key="index"
                height="42"
                :value="(item.sum / zeitProKundeSum) * 100"
                color="blue"
                :class="index + 1 === zeitProKunde.length ? 'mb-0' : 'mb-1'"
              >
                <v-sheet
                  color="transparent"
                  :style="{ width: 'calc(100% - 30px)' }"
                  class="d-flex justify-space-between"
                >
                  <v-sheet
                    color="transparent"
                    width="100%"
                    class="d-flex align-center text-truncate text-subtitle-2 font-weight-bold"
                    >{{ item.kunde | user(kunden) }}</v-sheet
                  >
                  <v-sheet
                    color="transparent"
                    width="150"
                    class="text-caption text-right"
                    >{{ item.sum }} h <br />{{
                      ((item.sum / zeitProKundeSum) * 100).toFixed(1)
                    }}%</v-sheet
                  >
                </v-sheet>
              </v-progress-linear>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card-title class="pb-0 pt-5">
              <v-btn text block>Umsatz pro Kunden</v-btn>
            </v-card-title>
            <v-card outlined class="rounded-lg">
              <v-progress-linear
                v-for="(item, index) in umsatzProKunde"
                :key="index"
                height="42"
                :value="(item.sum / umsatzProKundeBetragSum) * 100"
                color="blue"
                :class="index + 1 === umsatzProKunde.length ? 'mb-0' : 'mb-1'"
              >
                <v-sheet
                  color="transparent"
                  :style="{ width: 'calc(100% - 30px)' }"
                  class="d-flex justify-space-between"
                >
                  <v-sheet
                    color="transparent"
                    width="100%"
                    class="d-flex align-center text-truncate text-subtitle-2 font-weight-bold"
                    >{{ item.kunde | user(kunden) }}</v-sheet
                  >
                  <v-sheet
                    color="transparent"
                    width="150"
                    class="text-caption text-right"
                    >{{ item.sum | money }}<br />{{
                      ((item.sum / umsatzProKundeBetragSum) * 100).toFixed(1)
                    }}%</v-sheet
                  >
                </v-sheet>
              </v-progress-linear>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="4">
        <v-row>
          <!-- <v-col cols="12" md="6" lg="12">
            <emailStatus />
          </v-col> -->
          <v-col cols="12" md="6" lg="12">
            <uptimeMonitor />
          </v-col>
          <v-col cols="12" md="6" lg="12">
            <mainwpStats />
          </v-col>
          <v-col cols="12" md="6" lg="12">
            <v-card-title class="pb-2">
              <v-btn text block to="/zeiten"
                ><span class="text-center"
                  >Offene Stunden<br />{{ offeneZeitenSum }} h |
                  {{ offeneZeitenBetragSum | money }}</span
                ><v-icon>keyboard_arrow_right</v-icon></v-btn
              >
            </v-card-title>
            <v-card outlined class="rounded-lg">
              <v-progress-linear
                v-for="(item, index) in offeneZeiten"
                :key="index"
                height="42"
                :value="(item.sum / offeneZeitenSum) * 100"
                color="blue"
                :class="index + 1 === offeneZeiten.length ? 'mb-0' : 'mb-1'"
              >
                <v-sheet
                  color="transparent"
                  :style="{ width: 'calc(100% - 30px)' }"
                  class="d-flex justify-space-between"
                >
                  <v-sheet
                    color="transparent"
                    width="100%"
                    class="d-flex align-center text-truncate text-subtitle-2 font-weight-bold"
                    >{{ item.kunde | user(kunden) }}</v-sheet
                  >
                  <v-sheet
                    color="transparent"
                    width="150"
                    class="text-caption text-right"
                    >{{ item.sum }} h | {{ item.stundenlohn | money }}<br />{{
                      (item.sum * item.stundenlohn) | money
                    }}</v-sheet
                  >
                </v-sheet>
              </v-progress-linear>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" lg="12">
            <v-card-title class="pb-0 pt-5">
              <v-btn text block to="/vertrage"
                >Verträge ({{ summeVertrage[2] }})<v-icon
                  >keyboard_arrow_right</v-icon
                ></v-btn
              >
            </v-card-title>
            <v-card outlined class="py-4 rounded-lg d-flex justify-center">
              <v-progress-circular
                :rotate="-90"
                :width="16"
                color="blue"
                :value="(summeVertrage[0] / summeVertrage[1]) * 100"
                size="180"
              >
                <div class="text-h6">{{ summeVertrage[0] | money }}</div>
              </v-progress-circular>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import uptimeMonitor from "./dashboardCards/uptimeMonitor.vue";
import mainwpStats from "./dashboardCards/mainwpStats.vue";
// import emailStatus from "./dashboardCards/emailStatus.vue";
export default {
  components: {
    uptimeMonitor,
    mainwpStats,
    // emailStatus,
  },
  data() {
    return {
      showNeueFahrt: false,
      jahr: new Date().getFullYear(),
      offeneZeitenSum: 0,
      offeneZeitenBetragSum: 0,
      zeitProKundeSum: 0,
      zeitProKundeBetragSum: 0,
      umsatzProKundeSum: 0,
      umsatzProKundeBetragSum: 0,
    };
  },
  computed: {
    webseiten() {
      return this.$store.state.webseiten.webseiten;
    },
    emails() {
      return this.$store.state.emails.emails;
    },
    webseitenService() {
      return this.$store.state.vertrage.vertrage.filter(
        (item) => item.status === "Aktiv"
      );
    },
    rechnungen() {
      let rechnungen = [];
      this.$store.state.rechnungen.rechnungen.forEach((item) => {
        if (item.status === "Offen") {
          rechnungen.push(item);
        }
      });
      return rechnungen;
    },
    angebote() {
      let angebote = [];
      this.$store.state.angebote.angebote.forEach((item) => {
        if (item.status === "Offen") {
          angebote.push(item);
        }
      });
      return angebote;
    },
    kunden() {
      return this.$store.state.kunden.kunden;
    },
    currentWeek() {
      const d = new Date();
      d.setHours(0, 0, 0, 0);
      d.setDate(d.getDate() + 4 - (d.getDay() || 7));
      const yearStart = new Date(d.getFullYear(), 0, 1);
      const weekNumber = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
      return weekNumber;
    },
    summeVertrage() {
      let summe = [0, 0, 0];
      this.$store.state.vertrage.vertrage.forEach((vertrag) => {
        if (vertrag.status === "Aktiv") {
          summe[0] += vertrag.summe / vertrag.laufzeit;
          summe[2] += 1;
        }
        if (vertrag.status === "Entwurf") {
          summe[1] += vertrag.summe / vertrag.laufzeit;
        }
      });
      return summe;
    },
    offeneZeiten() {
      // eslint-disable-next-line
      this.offeneZeitenSum = 0;
      // eslint-disable-next-line
      let offeneZeitenBetragSum = 0;
      let zeiten = this.$store.state.zeiten.zeiten.filter(
        (item) => !item.abgerechnet
      );
      let result = [];
      const kunden = this.kunden;
      zeiten.reduce(function (res, value) {
        const kunde = kunden.find((item) => item.id === value.kunde);
        if (!res[value.kunde]) {
          res[value.kunde] = {
            kunde: value.kunde,
            sum: 0,
            stundenlohn: kunde.stundenlohn,
          };
          result.push(res[value.kunde]);
        }
        res[value.kunde].sum += value.dauer;
        offeneZeitenBetragSum += kunde.stundenlohn * value.dauer;
        return res;
      }, {});
      // eslint-disable-next-line
      this.offeneZeitenSum = zeiten.reduce(
        (sum, item) => sum + parseFloat(item.dauer),
        0
      );
      // eslint-disable-next-line
      this.offeneZeitenBetragSum = offeneZeitenBetragSum;
      return result;
    },
    zeitProKunde() {
      // eslint-disable-next-line
      this.zeitProKundeSum = 0;
      // eslint-disable-next-line
      let zeitProKundeBetragSum = 0;
      let zeiten = this.$store.state.zeiten.zeiten.filter(
        (zeit) => parseInt(zeit.datum.split("-")[0]) === this.jahr
      );
      let result = [];
      const kunden = this.kunden;
      zeiten.reduce(function (res, value) {
        const kunde = kunden.find((item) => item.id === value.kunde);
        if (!res[value.kunde]) {
          res[value.kunde] = {
            kunde: value.kunde,
            sum: 0,
            stundenlohn: kunde.stundenlohn,
          };
          result.push(res[value.kunde]);
        }
        res[value.kunde].sum += value.dauer;
        zeitProKundeBetragSum += kunde.stundenlohn * value.dauer;
        return res;
      }, {});
      // eslint-disable-next-line
      this.zeitProKundeSum = zeiten.reduce(
        (sum, item) => sum + parseFloat(item.dauer),
        0
      );
      // eslint-disable-next-line
      this.zeitProKundeBetragSum = zeitProKundeBetragSum;
      return result.sort((a, b) => b.sum - a.sum);
    },
    umsatzProKunde() {
      // eslint-disable-next-line
      this.umsatzProKundeSum = 0;
      // eslint-disable-next-line
      let umsatzProKundeBetragSum = 0;
      let rechnungen = this.$store.state.rechnungen.rechnungen.filter(
        (rechnung) =>
          parseInt(rechnung.zahlungsDatum?.split("-")[0]) === this.jahr
      );
      rechnungen = rechnungen.filter(
        (rechnung) => rechnung.status === "Gezahlt"
      );
      let result = [];
      const kunden = this.kunden;
      rechnungen.reduce(function (res, value) {
        const kunde = kunden.find((item) => item.id === value.kunde);
        if (!res[value.kunde]) {
          res[value.kunde] = {
            kunde: value.kunde,
            sum: 0,
            stundenlohn: kunde.stundenlohn,
          };
          result.push(res[value.kunde]);
        }
        res[value.kunde].sum += value.summe;
        umsatzProKundeBetragSum += value.summe;
        return res;
      }, {});
      // eslint-disable-next-line
      this.umsatzProKundeSum = rechnungen
        .reduce((sum, item) => sum + parseFloat(item.summe), 0)
        .toFixed(2);
      // eslint-disable-next-line
      this.umsatzProKundeBetragSum = umsatzProKundeBetragSum.toFixed(2);
      return result.sort((a, b) => b.sum - a.sum);
    },
    projekte() {
      let projekte = [];
      this.$store.state.projekte.projekte.forEach((item) => {
        if (item.status === "Offen") {
          projekte.push(item);
        }
      });
      return projekte;
    },
    heatmapData() {
      let jahr = this.jahr;
      let sum = 0;
      let days = 0;
      let date = new Date(new Date().setFullYear(jahr, 0, 1));
      let lastDate = new Date(new Date().setFullYear(jahr, 11, 31));
      let series = [];
      while (date < lastDate) {
        if (date.getDate() === 1) {
          series.push({
            name: date.toLocaleString("default", { month: "short" }),
            data: [],
            sum: 0,
            zeiten: [],
          });
        }
        series[date.getMonth()].data.push(0);
        date = new Date(date.setDate(date.getDate() + 1));
      }
      this.$store.state.zeiten.zeiten.forEach((zeit) => {
        let datum = zeit.datum.split("-");
        if (parseInt(datum[0]) === jahr) {
          sum += zeit.dauer;
          series[parseInt(datum[1] - 1)].sum =
            series[parseInt(datum[1] - 1)].sum + zeit.dauer;
          series[parseInt(datum[1] - 1)].data[parseInt(datum[2] - 1)] +=
            zeit.dauer;
          series[parseInt(datum[1]) - 1].zeiten.push(zeit);
        }
      });
      series.forEach((monat) => {
        monat.sumRound = parseInt(monat.sum);
        days += monat.data.filter((value) => value !== 0).length;
      });
      let returnData = {
        sum,
        days,
        series: series,
      };
      return returnData;
    },
    einnahmenMonat() {
      let jahr = this.jahr;
      let monat = 1;
      let monate = [];
      while (monat <= 12) {
        monate.push({
          jahr,
          monat: monat,
          rechnungen: [],
          eingenommen: 0,
        });
        monat++;
      }
      this.$store.state.rechnungen.rechnungen.forEach((item) => {
        if (item.status === "Gezahlt") {
          let datum = item.zahlungsDatum.split("-");
          if (parseInt(datum[0]) === jahr) {
            let array = monate.find(
              (monat) =>
                parseInt(datum[0]) === parseInt(monat.jahr) &&
                parseInt(datum[1]) === parseInt(monat.monat)
            );
            monate[monate.indexOf(array)].rechnungen.push(item);
            monate[monate.indexOf(array)].eingenommen += item.summe;
          }
        }
      });
      let data = {
        data: [
          {
            data: [],
            name: "Einnahmen",
          },
        ],
        sum: 0,
      };
      let max = 0;
      let sum = 0;
      monate.forEach((monat) => {
        data.data[0].data.push(monat.eingenommen);
        if (monat.eingenommen > max) {
          max = monat.eingenommen;
        }
        sum += monat.eingenommen;
      });
      data.sum = sum;
      return data;
    },
  },
  methods: {
    getColor(data) {
      let num = data.toFixed(0);
      switch (num) {
        case "0":
          return "grey lighten-5";
        case "1":
          return "blue lighten-4";
        case "2":
          return "blue lighten-3";
        case "3":
          return "blue lighten-3";
        case "4":
          return "blue lighten-1";
        case "5":
          return "blue lighten-1";
        case "6":
          return "blue ";
        case "7":
          return "blue darken-5";
        case "8":
          return "indigo darken-5";
        default:
          return "indigo darken-3";
      }
    },
    addJahr(num) {
      if (num === 0) {
        this.jahr = new Date().getFullYear();
      } else {
        this.jahr += num;
      }
    },
    openLinkInNewTab(url) {
      if (url !== undefined) {
        window.open(url, "_blank");
      }
    },
  },
};
</script>

<style>
.loader {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  height: 10px;
  margin-top: 0;
  z-index: 1000;
}
</style>
