import Vue from "vue";
import Vuex from "vuex";
import rechnungen from "./modules/rechnungen";
import emails from "./modules/emails";
import vertrage from "./modules/vertrage";
import angebote from "./modules/angebote";
import zeiten from "./modules/zeiten";
import user from "./modules/user";
import kunden from "./modules/kunden";
import webseiten from "./modules/webseiten";
import projekte from "./modules/projekte";
import aquisen from "./modules/aquisen";
import positionen from "./modules/positionen";
import ausgaben from "./modules/ausgaben";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    user,
    rechnungen,
    emails,
    vertrage,
    angebote,
    zeiten,
    kunden,
    webseiten,
    projekte,
    aquisen,
    positionen,
    ausgaben,
  },
});
