<template>
  <v-container fluid>
    <v-toolbar dense flat>
      <v-row no-gutters>
        <v-col cols="4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="neuePositionErstellen()"
                ><v-icon>add</v-icon></v-btn
              >
            </template>
            <span>Neue Position</span>
          </v-tooltip>
        </v-col>
        <v-col cols="8" md="2" offset-md="6" class="text-right">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Suchen"
            single-line
            solo
            flat
            hide-details
            clearable
            clear-icon="close"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-divider></v-divider>

    <v-row class="mt-1">
      <v-col cols="12" md="10" offset-md="1">
        <v-card outlined>
          <v-data-table
            :headers="headers"
            :items="positionen"
            :search="search"
            dense
            height="calc(100vh - 220px)"
            fixed-header
            hide-default-footer
            :items-per-page="-1"
            :hide-default-header="$vuetify.breakpoint.xsOnly"
            no-data-text="Keine Positionen vorhanden"
          >
            <template v-slot:header="">
              <thead>
                <tr class="hidden-sm-and-up">
                  <th>Nr</th>
                  <th>Kunde</th>
                  <th>Betrag</th>
                  <th>Status</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr
                v-on:dblclick="
                  (showPosition = true) &&
                    (selectedPosition = item) &&
                    (neuePosition = false)
                "
              >
                <td style="width: 100px">{{ item.number }}</td>
                <td class="py-1">
                  <span class="font-weight-bold">{{ item.title }}</span>
                </td>
                <td style="width: 100px">{{ item.anzahl }}</td>
                <td style="width: 140px" class="text-right">
                  {{ item.einzelpreis | money }}
                  <span class="grey--text"
                    ><br />{{ (item.einzelpreis * 1.19) | money }}</span
                  >
                </td>

                <td style="width: 20px" class="hidden-xs-only">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="duplicatePosition(item)"
                        ><v-icon>content_copy</v-icon></v-btn
                      >
                    </template>
                    <span>Duplizieren</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showPosition"
      width="80%"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span v-if="neuePosition">Neue Position</span>
          <span v-else>Position bearbeiten</span>
          <v-spacer></v-spacer>
          <v-btn icon class="white--text" @click="showPosition = false"
            ><v-icon>close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          <v-row v-if="selectedPosition">
            <v-col cols="2"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedPosition.number"
                label="Nr"
              ></v-text-field
            ></v-col>
            <v-col cols="8"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedPosition.title"
                label="Titel"
              ></v-text-field
            ></v-col>
            <v-col cols="2">
              <v-text-field
                class="my-1"
                dense
                outlined
                hide-details
                label="Anzahl"
                v-model="selectedPosition.anzahl"
                placeholder="5.00"
              ></v-text-field>
              <v-text-field
                class="my-1"
                dense
                outlined
                hide-details
                label="Preis"
                v-model="selectedPosition.einzelpreis"
                placeholder="10.00"
              ></v-text-field>
              <v-text-field
                class="my-1"
                dense
                filled
                hide-details
                readonly
                :placeholder="
                  (selectedPosition.anzahl * selectedPosition.einzelpreis)
                    | money
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12"
              ><v-textarea
                dense
                outlined
                hide-details
                auto-grow
                v-model="selectedPosition.description"
                label="Beschreibung"
              ></v-textarea
            ></v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            class="red--text"
            icon
            @click="deletePosition(selectedPosition.id)"
            ><v-icon>delete</v-icon></v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="showPosition = false">Abbrechen</v-btn>
          <v-btn
            class="primary"
            v-if="neuePosition"
            @click="createPosition()"
            dark
            text
            >Erstellen</v-btn
          >
          <v-btn class="primary" v-else @click="updatePosition()" dark text
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      showPosition: false,
      neuePosition: false,
      selectedPosition: {},
      headers: [
        { text: "Nr", value: "number" },
        { text: "Titel", value: "title" },
        { text: "Anz", value: "anzahl" },
        { text: "Preis", value: "einzelpreis" },
        { text: "", value: "" },
      ],
    };
  },
  computed: {
    positionen() {
      const positionen = this.$store.state.positionen.positionen;
      return positionen;
    },
  },
  methods: {
    createPosition() {
      this.$store.dispatch("positionen/createPosition", this.selectedPosition);
      this.showPosition = false;
      this.selectedPosition = {};
    },
    duplicatePosition(item) {
      this.selectedPosition = item;
      this.neuePosition = true;
      this.showPosition = true;
    },
    updatePosition() {
      this.$store.dispatch(
        "positionen/updatePositionData",
        this.selectedPosition
      );
      this.showPosition = false;
      this.selectedPosition = {};
    },
    deletePosition(id) {
      this.$store.dispatch("positionen/deletePositionData", id);
      this.showPosition = false;
      this.selectedPosition = {};
    },
    neuePositionErstellen() {
      this.selectedPosition = {};
      this.neuePosition = true;
      this.showPosition = true;
    },
  },
};
</script>

<style>
.loader {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  height: 10px;
  margin-top: 0;
  z-index: 1000;
}
</style>
