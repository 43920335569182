import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import App from "./App";
import router from "./router";
import { store } from "./store";
import colors from "vuetify/lib/util/colors";
import firebase from "../firebase.js";
Vue.prototype.$firebase = firebase;

import VueApexCharts from "vue-apexcharts";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);
Vue.config.productionTip = false;

// ****************************************************************
// Import Filter
// ****************************************************************
import DateFilter from "./filters/date.js";
import UserFilter from "./filters/user.js";
import MoneyFilter from "./filters/money.js";
import ProjektFilter from "./filters/projekt.js";
Vue.filter("date", DateFilter);
Vue.filter("user", UserFilter);
Vue.filter("money", MoneyFilter);
Vue.filter("projekt", ProjektFilter);

// ****************************************************************
// Import globale Variablen und Funktionen
// ****************************************************************
import vars from "./main_variablen.js";
Vue.prototype.$vars = vars;

const vuetify = new Vuetify({
  theme: {
    icons: {
      iconfont: "mdi",
    },
    dark: false,
    themes: {
      light: {
        primary: "#36404F", // #F57C00
        secondary: colors.red.base, // #FFCDD2
        accent: colors.indigo.base, // #3F51B5
      },
      dark: {
        primary: "#36404F", // #F57C00
        secondary: colors.red.base, // #FFCDD2
        accent: colors.indigo.base, // #3F51B5
      },
    },
  },
});

Vue.use(VueApexCharts);
Vue.component("apexChart", VueApexCharts);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.$store.dispatch("user/autoSignIn", user);
      }
      this.$store.dispatch("rechnungen/loadRechnungen");
      this.$store.dispatch("emails/loadEmails");
      this.$store.dispatch("angebote/loadAngebote");
      this.$store.dispatch("kunden/loadKunden");
      this.$store.dispatch("zeiten/loadZeiten");
      this.$store.dispatch("webseiten/loadWebseiten");
      this.$store.dispatch("projekte/loadProjekte");
      this.$store.dispatch("vertrage/loadVertrage");
      this.$store.dispatch("aquisen/loadAquisen");
      this.$store.dispatch("positionen/loadPositionen");
      this.$store.dispatch("ausgaben/loadAusgaben");
    });
  },
}).$mount("#app");
