<template>
  <v-app>
    <div id="blaueLeiste" class="primary"></div>
    <div id="weißeLeiste" class="white hidden-sm-and-up"></div>
    <v-app-bar
      v-if="userIsAuthenticated"
      flat
      dark
      app
      :clipped-left="$vuetify.breakpoint.mdAndUp"
      class="primary"
    >
      <v-toolbar-title>Breuer Webservice | {{ route }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        class="hidden-sm-and-down mr-1"
        outlined
        href="https://inspectwp.com"
        target="_blank"
        >Inspect WP</v-btn
      >
      <v-btn
        class="hidden-sm-and-down mr-1"
        outlined
        href="https://login.mittwald.de/index.php?id=16"
        target="_blank"
        >Mittwald</v-btn
      >
      <v-btn
        class="hidden-sm-and-down mr-1"
        outlined
        href="https://www.wordfence.com/central/"
        target="_blank"
        >Wordfence</v-btn
      >
      <v-btn
        class="hidden-sm-and-down mr-1"
        outlined
        href="https://benediktbreuer.de/wp-admin/admin.php?page=mainwp_tab"
        target="_blank"
        >MainWP</v-btn
      >
      <v-btn
        class="hidden-sm-and-down"
        outlined
        href="https://www.benediktbreuer.de"
        target="_blank"
        >benediktbreuer.de</v-btn
      >
      <v-btn icon @click="changePrivate()" v-if="!$vars.private"
        ><v-icon>lock_open</v-icon></v-btn
      >
      <v-btn icon @click="changePrivate()" v-else><v-icon>lock</v-icon></v-btn>
      <v-btn
        icon
        @click="
          showNeueZeit = !showNeueZeit;
          neueZeit = neueZeitDefault;
        "
        ><v-icon>more_time</v-icon></v-btn
      >
      <v-btn icon @click="onLogout()"><v-icon>logout</v-icon></v-btn>
    </v-app-bar>

    <v-dialog
      v-if="userIsAuthenticated"
      v-model="showNeueZeit"
      width="50%"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>
          Zeit erfassen
          <v-btn icon @click="refreshAddZeit()"><v-icon>refresh</v-icon></v-btn>
          <v-spacer></v-spacer>
          <v-btn icon @click="showNeueZeit = false"
            ><v-icon>close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          <v-row>
            <v-col cols="12" md="6"
              ><v-autocomplete
                dense
                outlined
                hide-details
                :items="kundenSelect"
                v-model="neueZeit.kunde"
                label="Kunde"
              ></v-autocomplete
            ></v-col>
            <v-col cols="12" md="6"
              ><v-autocomplete
                dense
                outlined
                hide-details
                :items="projekteSelect"
                v-model="neueZeit.projekt"
                label="Projekt"
              ></v-autocomplete
            ></v-col>
            <v-col cols="12" md="6"
              ><v-combobox
                dense
                outlined
                hide-details
                :items="titelItems"
                v-model="neueZeit.titel"
                label="Titel"
              ></v-combobox
            ></v-col>
            <v-col cols="12" md="6"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="neueZeit.anmerkung"
                label="Anmerkung"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="4">
              <v-menu
                ref="neueZeit.datum"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    prepend-icon="keyboard_arrow_left"
                    append-outer-icon="keyboard_arrow_right"
                    @click:prepend="changeDate(-1)"
                    @click:append-outer="changeDate(1)"
                    hide-details
                    outlined
                    dense
                    v-model="formatDatum"
                    label="Datum"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  prev-icon="keyboard_arrow_left"
                  next-icon="keyboard_arrow_right"
                  v-model="neueZeit.datum"
                  locale="de-de"
                  first-day-of-week="1"
                  @input="menu = false"
                  no-title
                  scrollable
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="neueZeit.von"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="neueZeit.von"
                    label="Von"
                    outlined
                    dense
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="neueZeit.von"
                  full-width
                  :allowed-minutes="[0, 15, 30, 45]"
                  format="24hr"
                  @change="changeTime('time')"
                  @click:minute="$refs.menu2.save(neueZeit.von)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="neueZeit.bis"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="neueZeit.bis"
                    label="Bis"
                    outlined
                    dense
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu1"
                  v-model="neueZeit.bis"
                  full-width
                  :allowed-minutes="[0, 15, 30, 45]"
                  format="24hr"
                  @change="changeTime('time')"
                  @click:minute="$refs.menu1.save(neueZeit.bis)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-range-slider
                dense
                :tick-labels="[
                  0,
                  null,
                  null,
                  null,
                  1,
                  null,
                  null,
                  null,
                  2,
                  null,
                  null,
                  null,
                  3,
                  null,
                  null,
                  null,
                  4,
                  null,
                  null,
                  null,
                  5,
                  null,
                  null,
                  null,
                  6,
                  null,
                  null,
                  null,
                  7,
                  null,
                  null,
                  null,
                  8,
                  null,
                  null,
                  null,
                  9,
                  null,
                  null,
                  null,
                  10,
                  null,
                  null,
                  null,
                  11,
                  null,
                  null,
                  null,
                  12,
                  null,
                  null,
                  null,
                  13,
                  null,
                  null,
                  null,
                  14,
                  null,
                  null,
                  null,
                  15,
                  null,
                  null,
                  null,
                  16,
                  null,
                  null,
                  null,
                  17,
                  null,
                  null,
                  null,
                  18,
                  null,
                  null,
                  null,
                  19,
                  null,
                  null,
                  null,
                  20,
                  null,
                  null,
                  null,
                  21,
                  null,
                  null,
                  null,
                  22,
                  null,
                  null,
                  null,
                  23,
                ]"
                v-model="neueZeit.range"
                :max="95"
                thumb-label="always"
                @change="changeTime('range')"
              >
                <template v-slot:thumb-label="{ value }">
                  {{ mapToTime(value) }}
                </template>
              </v-range-slider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          {{ dauer }}
          <v-btn text @click="showNeueZeit = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn primary @click="saveZeit()">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-main app class="grey lighten-4">
      <router-view></router-view>
    </v-main>
    <v-bottom-navigation
      v-if="userIsAuthenticated"
      app
      fixed
      color="primary"
      class="hidden-sm-and-down"
    >
      <v-btn to="/"><span>Home</span><v-icon>home</v-icon></v-btn>
      <v-btn to="/zeiten"
        ><span>Zeiten</span><v-icon>pending_actions</v-icon></v-btn
      >
      <v-btn to="/angebote"><span>Angebote</span><v-icon>list</v-icon></v-btn>
      <v-btn to="/projekte"><span>Projekte</span><v-icon>folder</v-icon></v-btn>
      <v-btn to="/rechnungen"
        ><span>Rechnungen</span><v-icon>request_page</v-icon></v-btn
      >
      <v-btn to="/vertrage"
        ><span>Verträge</span><v-icon>description</v-icon></v-btn
      >
      <v-btn to="/ausgaben"><span>Ausgaben</span><v-icon>list</v-icon></v-btn>
      <v-btn to="/positionen"
        ><span>Positionen</span><v-icon>list</v-icon></v-btn
      >
      <v-btn to="/kunden"><span>Kunden</span><v-icon>group</v-icon></v-btn>
      <v-btn to="/webseiten"><span>Webseiten</span><v-icon>web</v-icon></v-btn>
      <v-btn to="/aquisen"><span>Aquisen</span><v-icon>web</v-icon></v-btn>
      <v-btn to="/spiele"
        ><span>Spiele</span><v-icon>sports_esports</v-icon></v-btn
      >
    </v-bottom-navigation>

    <v-bottom-navigation
      v-if="userIsAuthenticated"
      grow
      app
      fixed
      color="primary"
      class="hidden-md-and-up mb-6"
    >
      <v-btn to="/angebote"><span>Angebote</span><v-icon>list</v-icon></v-btn>
      <v-btn to="/projekte"><span>Projekte</span><v-icon>folder</v-icon></v-btn>
      <v-btn to="/"><span>Home</span><v-icon>home</v-icon></v-btn>
      <v-btn to="/rechnungen"
        ><span>Rechnungen</span><v-icon>request_page</v-icon></v-btn
      >
      <v-btn to="/kunden"><span>Kunden</span><v-icon>group</v-icon></v-btn>
    </v-bottom-navigation>

    <v-bottom-navigation
      v-if="userIsAuthenticated"
      grow
      app
      fixed
      color="primary"
      class="hidden-md-and-up mb-6"
    >
      <v-btn to="/angebote"><span>Angebote</span><v-icon>list</v-icon></v-btn>
      <v-btn to="/projekte"><span>Projekte</span><v-icon>folder</v-icon></v-btn>
      <v-btn to="/"><span>Home</span><v-icon>home</v-icon></v-btn>
      <v-btn to="/rechnungen"
        ><span>Rechnungen</span><v-icon>request_page</v-icon></v-btn
      >
      <v-btn to="/kunden"><span>Kunden</span><v-icon>group</v-icon></v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      sideNav: null,
      neueZeit: {},
      menu1: "",
      menu2: "",
      menu: false,
      dauer: null,
      date: "",
      showNeueZeit: false,
    };
  },
  computed: {
    kunden() {
      return this.$store.state.kunden.kunden;
    },
    neueZeitDefault() {
      const von = new Date(
        new Date().setMinutes(Math.ceil(new Date().getMinutes() / 15) * 15 - 60)
      ).toLocaleTimeString("de-De", {
        hour12: false,
        hour: "numeric",
        minute: "2-digit",
      });
      const bis = new Date(
        new Date().setMinutes(Math.ceil(new Date().getMinutes() / 15) * 15)
      ).toLocaleTimeString("de-De", {
        hour12: false,
        hour: "numeric",
        minute: "2-digit",
      });
      const range = [this.mapToValue(von), this.mapToValue(bis)];
      console.log(range);
      return {
        datum: new Date().toISOString().substr(0, 10),
        von,
        bis,
        range,
      };
    },
    kundenSelect() {
      let kunden = [];
      this.$store.state.kunden.kunden.forEach((kunde) => {
        kunden.push({
          value: kunde.id,
          text:
            kunde.firma + " (" + kunde.nachname + ", " + kunde.vorname + ")",
        });
      });
      return kunden;
    },
    projekteSelect() {
      let projekte = [];
      this.$store.state.projekte.projekte.forEach((projekt) => {
        if (this.neueZeit.kunde !== undefined) {
          if (
            projekt.kunde === this.neueZeit.kunde &&
            projekt.status === "Offen"
          ) {
            projekte.push({
              value: projekt.id,
              text: projekt.titel,
            });
          }
        }
      });
      return projekte;
    },
    titelItems() {
      let items = [];
      if (this.neueZeit.projekt !== undefined) {
        this.$store.state.zeiten.zeiten.forEach((zeit) => {
          if (zeit.projekt === this.neueZeit.projekt) {
            items.push(zeit.titel);
          }
        });
      }
      return items;
    },
    route() {
      return this.$route.name;
    },
    x() {
      if (
        this.$vuetify.breakpoint.name === "sm" ||
        this.$vuetify.breakpoint.name === "xs"
      ) {
        return (window.innerWidth - 367) / 2;
      } else {
        return window.innerWidth - 367 - 16;
      }
    },
    userIsAuthenticated() {
      console.log(
        new Date(
          new Date().setMinutes(
            Math.ceil(new Date().getMinutes() / 15) * 15 - 30
          )
        )
      );
      return (
        this.$store.state.user.user !== null &&
        this.$store.state.user.user !== undefined
      );
    },
    formatDatum() {
      if (!this.neueZeit.datum) return null;
      return new Date(this.neueZeit.datum).toLocaleString(["de-de"], {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
    },
  },
  methods: {
    mapToTime(value) {
      const hours = Math.floor(value / 4);
      const minutes = (value % 4) * 15;
      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");
      return `${formattedHours}:${formattedMinutes}`;
    },
    mapToValue(time) {
      console.log(time);
      const [hours, minutes] = time.split(":").map(Number);
      const value = hours * 4 + Math.floor(minutes / 15);
      console.log(value);
      return value;
    },
    changeDate(value) {
      const currentDate = new Date(this.neueZeit.datum);
      currentDate.setDate(currentDate.getDate() + value);
      this.neueZeit.datum = currentDate.toISOString().substr(0, 10);
    },
    changeTime(type) {
      if (type === "range") {
        this.neueZeit.von = this.mapToTime(this.neueZeit.range[0]);
        this.neueZeit.bis = this.mapToTime(this.neueZeit.range[1]);
      }
      if (type === "time") {
        this.neueZeit.range[0] = this.mapToValue(this.neueZeit.von);
        this.neueZeit.range[1] = this.mapToValue(this.neueZeit.bis);
      }
    },
    refreshAddZeit() {
      let zeiten = [...this.$store.state.zeiten.zeiten];
      zeiten.sort((a, b) => {
        const dateComparison = new Date(a.datum) - new Date(b.datum);
        return dateComparison !== 0
          ? dateComparison
          : a.von.localeCompare(b.von);
      });
      this.neueZeit = {
        ...this.neueZeitDefault,
        anmerkung: zeiten[zeiten.length - 1].anmerkung,
        kunde: zeiten[zeiten.length - 1].kunde,
        projekt: zeiten[zeiten.length - 1].projekt,
        titel: zeiten[zeiten.length - 1].titel,
      };
      console.log(this.neueZeit);
    },
    saveZeit() {
      let zeit = this.neueZeit;
      let dauer = 0;
      let von = this.neueZeit.von.split(":");
      let bis = this.neueZeit.bis.split(":");
      dauer = bis[0] - von[0];
      if (von[1] === "15") {
        dauer = dauer - 0.25;
      }
      if (von[1] === "30") {
        dauer = dauer - 0.5;
      }
      if (von[1] === "45") {
        dauer = dauer - 0.75;
      }
      if (bis[1] === "15") {
        dauer = dauer + 0.25;
      }
      if (bis[1] === "30") {
        dauer = dauer + 0.5;
      }
      if (bis[1] === "45") {
        dauer = dauer + 0.75;
      }
      zeit.dauer = dauer;
      zeit.abgerechnet = false;
      zeit.eingetragen = new Date();
      console.log(zeit);
      this.$store.dispatch("zeiten/createZeit", zeit);
      this.neueZeit = {};
      this.showNeueZeit = false;
    },
    changePrivate() {
      console.log("changePrivate");
      this.$vars.private = !this.$vars.private;
    },
    onLogout() {
      this.$store.dispatch("user/logout");
      this.$router.push("/");
    },
  },
  mounted() {
    let now = new Date();
    let hours = String(now.getHours()).padStart(2, "0");
    let minutes = String(now.getMinutes()).padStart(2, "0");
    this.neueZeit.range[0] = this.mapToValue(`${hours}:${minutes}`);
    now = new Date();
    hours = String(now.getHours() + 2).padStart(2, "0");
    minutes = String(now.getMinutes()).padStart(2, "0");
    this.neueZeit.range[0] = this.mapToValue(`${hours}:${minutes}`);
  },
};
</script>

<style lang="stylus">
@import './stylus/main'
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500');

.v-application {
  font-family: "Ubuntu", sans-serif !important;

}
.test{
  position: fixed;
  top: -100px;
  left: 0px;
  width: 100%;
  height: 100px;
  z-index: 2000;
}
#blaueLeiste{
  position: fixed;
  top: -100px;
  left: 0px;
  width: 100%;
  height: 100px;
  z-index: 2000;
}
#weißeLeiste{
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 25px;
  z-index: 2000;
}
.container--fluid{
  padding: 0px;
  margin: 0px;
}
</style>
