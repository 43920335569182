<template>
  <v-container fluid>
    <v-toolbar dense flat>
      <v-row no-gutters>
        <v-col cols="4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="neueAusgabeErstellen()"
                ><v-icon>add</v-icon></v-btn
              >
            </template>
            <span>Neue Ausgabe</span>
          </v-tooltip>
        </v-col>
        <v-col cols="8" md="2" offset-md="6" class="text-right">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Suchen"
            single-line
            solo
            flat
            hide-details
            clearable
            clear-icon="close"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-divider></v-divider>

    <v-row class="mt-1">
      <v-col cols="12" md="3" offset-md="1">
        <!-- Card für Ausgaben 2025 vom Betrag ohne Steuer -->
        <v-card outlined class="pa-4">
          <v-card-title class="text-h6"
            >Ausgaben 2025 (Betrag ohne Steuer)</v-card-title
          >
          <v-card-text>
            <v-row>
              <v-col>
                <span class="display-1">
                  {{
                    ausgaben
                      .filter(
                        (ausgabe) =>
                          new Date(ausgabe.date).getFullYear() === 2025
                      )
                      .reduce(
                        (total, ausgabe) => total + ausgabe.betragOhneSteuer,
                        0
                      ) | money
                  }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- Card für die gesamte gezahlte MwSt -->
        <v-card outlined class="pa-4 mt-4">
          <v-card-title class="text-h6">Gesamte gezahlte MwSt</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <span class="display-1">
                  {{
                    ausgaben
                      .filter(
                        (ausgabe) =>
                          new Date(ausgabe.date).getFullYear() === 2025
                      )
                      .reduce(
                        (total, ausgabe) =>
                          total +
                          (ausgabe.betragMitSteuer - ausgabe.betragOhneSteuer),
                        0
                      ) | money
                  }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- Füge einen Button für Multi-File Upload ein. Automatisch wird aus jedem Dokument ein Eintrag erstellt. Ohne Dialog oder so. Aber mit einer Fortschrittsanzeige. -->
        <v-card outlined class="pa-4 mt-4">
          <v-card-title class="text-h6">Multi-File Upload</v-card-title>
          <v-card-text>
            <v-file-input
              multiple
              v-model="multipleFiles"
              label="Dateien auswählen"
              accept=".pdf,.jpg,.png"
              outlined
              dense
              hide-details
            ></v-file-input>
            <v-btn
              class="primary mt-2"
              :disabled="!multipleFiles || uploadingMultiple"
              @click="uploadMultipleFiles"
              dark
              text
              >Upload starten</v-btn
            >
            <v-progress-linear
              v-if="uploadingMultiple"
              :value="uploadProgress"
              height="10"
              class="mt-2"
            ></v-progress-linear>
          </v-card-text>
        </v-card>

        <!-- Card für die Kosten pro Kategorie -->

        <v-card outlined class="mt-4">
          <v-card-title class="text-h6"
            >Gesamtkosten pro Kategorie</v-card-title
          >
          <v-card-text>
            <v-list dense>
              <v-list-item
                v-for="(item, index) in kostenProKategorie"
                :key="index"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.kategorie }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <span>{{ item.summe | money }}</span>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card outlined>
          <v-data-table
            :headers="headers"
            :items="ausgaben"
            :search="search"
            dense
            height="calc(100vh - 220px)"
            fixed-header
            hide-default-footer
            :items-per-page="-1"
            :hide-default-header="$vuetify.breakpoint.xsOnly"
            no-data-text="Keine Ausgaben vorhanden"
          >
            <template v-slot:header="">
              <thead>
                <tr class="hidden-sm-and-up">
                  <th>Datum</th>
                  <th>Betrag mit Steuer</th>
                  <th>Betrag ohne Steuer</th>
                  <th>Steuersatz</th>
                  <th>Kategorie</th>
                  <th>Empfänger</th>
                  <th>Datei</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr
                v-on:dblclick="
                  (showAusgabe = true) &&
                    (selectedAusgabe = item) &&
                    (neueAusgabe = false)
                "
              >
                <td>{{ item.date | date("DDMMYYYY") }}</td>
                <td style="width: 130px">{{ item.betragMitSteuer | money }}</td>
                <td style="width: 13cap">
                  {{ item.betragOhneSteuer | money }}
                </td>
                <td style="width: 120px">{{ item.steuersatz }}%</td>
                <td style="width: 250px">{{ item.kategorie }}</td>
                <td>{{ item.empfaenger }}</td>
                <td style="width: 50px" v-if="item.dateiUrl">
                  <v-btn icon :href="item.dateiUrl" target="_blank">
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </td>
                <td style="width: 50px" v-else></td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showAusgabe"
      width="80%"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span v-if="neueAusgabe">Neue Ausgabe</span>
          <span v-else>Ausgabe bearbeiten</span>
          <v-spacer></v-spacer>
          <v-btn icon class="white--text" @click="showAusgabe = false"
            ><v-icon>close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          <v-row v-if="selectedAusgabe">
            <v-col cols="6"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedAusgabe.date"
                label="Datum"
                type="date"
              ></v-text-field
            ></v-col>
            <v-col cols="6"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model.number="selectedAusgabe.betragMitSteuer"
                label="Betrag mit Steuer"
                :rules="[
                  (v) =>
                    /^\d+,\d{2}$/.test(v) ||
                    'Nur Zahlen im Format 3222,33 erlaubt',
                ]"
                @input="
                  selectedAusgabe.betragMitSteuer =
                    selectedAusgabe.betragMitSteuer.replace(',', '.')
                "
              ></v-text-field
            ></v-col>
            <v-col cols="6"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model.number="selectedAusgabe.betragOhneSteuer"
                label="Betrag ohne Steuer"
                :rules="[
                  (v) =>
                    /^\d+,\d{2}$/.test(v) ||
                    'Nur Zahlen im Format 3222,33 erlaubt',
                ]"
                @input="
                  selectedAusgabe.betragOhneSteuer =
                    selectedAusgabe.betragOhneSteuer.replace(',', '.')
                "
              ></v-text-field
            ></v-col>
            <v-col cols="6"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model.number="selectedAusgabe.steuersatz"
                label="Steuersatz"
                :rules="[
                  (v) =>
                    /^\d{1,2}$/.test(v) || 'Steuersatz muss zweistellig sein',
                ]"
              ></v-text-field
            ></v-col>
            <v-col cols="6">
              <v-select
                dense
                outlined
                hide-details
                v-model="selectedAusgabe.kategorie"
                label="Kategorie"
                :items="[
                  'Beruflich: Anschaffungen unter 1000€',
                  'Beruflich: Anschaffungen über 1000€',
                  'Beruflich: Telekommunikation',
                  'Beruflich: Versicherungen beruflich',
                  'Beruflich: EDV-Kosten unter 150€',
                  'Beruflich: Bürobedarf',
                  'Beruflich: Fahrtkosten',
                  'Beruflich: Verpflegungspauschale',
                  'Beruflich: Büromiete',
                  'Beruflich: Gewerbe sonstiges',
                  'Privat: Spende',
                  'Privat: Versicherungen privat',
                  'Privat: Arztkosten/Medikamente',
                ]"
              ></v-select>
            </v-col>
            <v-col cols="6"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedAusgabe.empfaenger"
                label="Empfänger"
              ></v-text-field
            ></v-col>
            <v-col cols="12"
              ><v-textarea
                dense
                outlined
                hide-details
                auto-grow
                v-model="selectedAusgabe.beschreibung"
                label="Beschreibung"
              ></v-textarea
            ></v-col>
            <v-col cols="12"
              ><v-file-input
                dense
                outlined
                hide-details
                v-model="selectedAusgabe.datei"
                label="Datei"
                accept=".pdf,.jpg,.png"
              >
                <template v-slot:append-outer>
                  <v-btn
                    class="primary mb-2"
                    :loading="loading"
                    :disabled="!selectedAusgabe.datei"
                    @click="analyseData(selectedAusgabe)"
                    dark
                    text
                    >Analysieren</v-btn
                  >
                </template>
              </v-file-input></v-col
            >
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            class="red--text"
            icon
            @click="deleteAusgabe(selectedAusgabe.id)"
            ><v-icon>delete</v-icon></v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="showAusgabe = false">Abbrechen</v-btn>
          <v-btn
            class="primary"
            v-if="neueAusgabe"
            @click="createAusgabe()"
            dark
            text
            >Erstellen</v-btn
          >
          <v-btn class="primary" v-else @click="updateAusgabe()" dark text
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loading" persistent width="600">
      <v-card height="400" class="d-flex justify-center align-center">
        <div>
          <v-card-title class="text-center">Loading</v-card-title>
          <v-card-text class="text-center">
            Bitte warten, während die Analyse durchgeführt wird.
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import axios from "axios";
import OpenAI from "openai";

export default {
  data() {
    return {
      search: "",
      showAusgabe: false,
      neueAusgabe: false,
      selectedAusgabe: {},
      loading: false,
      multipleFiles: [],
      uploadingMultiple: false,
      uploadProgress: 0,
      headers: [
        { text: "Datum", value: "date" },
        { text: "Betrag mit Steuer", value: "betragMitSteuer" },
        { text: "Betrag ohne Steuer", value: "betragOhneSteuer" },
        { text: "Steuersatz", value: "steuersatz" },
        { text: "Kategorie", value: "kategorie" },
        { text: "Empfänger", value: "empfaenger" },
        { text: "Datei", value: "dateiUrl" },
        { text: "", value: "" },
      ],
    };
  },
  computed: {
    ausgaben() {
      const ausgaben = this.$store.state.ausgaben.ausgaben;
      return ausgaben;
    },
    kostenProKategorie() {
      const groups = {};
      this.ausgaben.forEach((ausgabe) => {
        const kategorie = ausgabe.kategorie || "Unbekannt";
        if (!groups[kategorie]) {
          groups[kategorie] = 0;
        }
        groups[kategorie] += ausgabe.betragOhneSteuer;
      });
      return Object.keys(groups)
        .sort()
        .map((key) => ({ kategorie: key, summe: groups[key] }));
    },
  },
  methods: {
    async uploadFile(file) {
      if (!file) return;

      const storage = getStorage();
      const fileRef = ref(storage, `uploads/${file.name}`);

      try {
        const snapshot = await uploadBytes(fileRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        return downloadURL; // Rückgabe der URL nach dem Hochladen
      } catch (error) {
        console.error("Fehler beim Hochladen der Datei:", error);
        return null;
      }
    },
    async createAusgabe() {
      if (this.selectedAusgabe.datei) {
        const url = await this.uploadFile(this.selectedAusgabe.datei);
        this.selectedAusgabe.dateiUrl = url; // Speichert die URL, nicht das File-Objekt
        delete this.selectedAusgabe.datei; // Entfernt das File-Objekt aus dem Speicher
      }

      await this.$store.dispatch(
        "ausgaben/createAusgabe",
        this.selectedAusgabe
      );
      this.showAusgabe = false;
      this.selectedAusgabe = {};
    },
    updateAusgabe() {
      this.$store.dispatch("ausgaben/updateAusgabeData", this.selectedAusgabe);
      this.showAusgabe = false;
      this.selectedAusgabe = {};
    },
    deleteAusgabe(id) {
      this.$store.dispatch("ausgaben/deleteAusgabeData", id);
      this.showAusgabe = false;
      this.selectedAusgabe = {};
    },
    neueAusgabeErstellen() {
      this.selectedAusgabe = {};
      this.neueAusgabe = true;
      this.showAusgabe = true;
    },
    async uploadMultipleFiles() {
      if (!this.multipleFiles || this.multipleFiles.length === 0) return;
      this.uploadingMultiple = true;
      const totalFiles = this.multipleFiles.length;
      for (let i = 0; i < totalFiles; i++) {
        const file = this.multipleFiles[i];
        console.log("Uploading file:", file);
        const item = {
          datei: file,
        };
        await this.analyseData(item);
        console.log("Analysed data:", item);
        if (item) {
          item.dateiUrl = await this.uploadFile(item.datei);
          delete item.datei;
          await this.$store.dispatch("ausgaben/createAusgabe", item);
          console.log("Created: ", i);
        }
        this.uploadProgress = Math.round(((i + 1) / totalFiles) * 100);
        console.log(this.uploadProgress);
      }
      this.multipleFiles = [];
      this.uploadingMultiple = false;
      this.uploadProgress = 0;
    },
    async analyseData(item) {
      if (!item.datei) {
        alert("Keine Datei zum Analysieren vorhanden.");
        return;
      }

      this.loading = true;
      const openai = new OpenAI({
        apiKey: process.env.VUE_APP_OPENAI_API_KEY,
        dangerouslyAllowBrowser: true,
      });

      try {
        // Datei an OpenAI hochladen
        const formData = new FormData();
        formData.append("file", item.datei, "uploaded-file.pdf"); // Setze hier den Dateinamen, je nachdem welche Datei du hast
        if (
          item.datei.type === "image/jpeg" ||
          item.datei.type === "image/jpg" ||
          item.datei.type === "image/png"
        ) {
          // Wenn es sich um ein Bild handelt, dann setze 'purpose' auf 'vision' für bessere Antworten
          formData.append("purpose", "vision");
        } else {
          // Ansonsten 'file' für allgemeine Antworten
          formData.append("purpose", "assistants");
        }
        // "'answers' is not one of ['fine-tune', 'assistants', 'batch', 'user_data', 'vision', 'evals'] - 'purpose'"
        const uploadResponse = await axios.post(
          "https://api.openai.com/v1/files",
          formData,
          {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_OPENAI_API_KEY}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const fileId = uploadResponse.data.id;
        const response2 = await openai.responses.create({
          model: "gpt-4o-mini",
          input: [
            {
              role: "user",
              content: [
                {
                  type: "input_text",
                  text: "Analysiere die angehängte Datei und gib die relevanten Daten als JSON zurück. Das Datum soll im Format 'YYYY-MM-DD' sein.",
                },
                {
                  type: "input_file",
                  file_id: fileId,
                },
              ],
            },
          ],
          text: {
            format: {
              type: "json_schema",
              name: "ausgaben_schema",
              schema: {
                type: "object",
                properties: {
                  date: { type: "string" },
                  betragMitSteuer: { type: "number" },
                  betragOhneSteuer: { type: "number" },
                  steuersatz: { type: "number" },
                  beschreibung: {
                    type: "string",
                    description:
                      "Beschreibe die Rechnung und die Positionen kurz auf Deutsch. Erkläre auch warum du es in die Kategorie einsortiert hast.",
                  },
                  kategorie: {
                    type: "string",
                    description: "category of the expense",
                    enum: [
                      "Beruflich: Anschaffungen unter 1000€",
                      "Beruflich: Anschaffungen über 1000€",
                      "Beruflich: Telekommunikation",
                      "Beruflich: Versicherungen beruflich",
                      "Beruflich: EDV-Kosten unter 150€",
                      "Beruflich: Bürobedarf",
                      "Beruflich: Fahrtkosten",
                      "Beruflich: Verpflegungspauschale",
                      "Beruflich: Büromiete",
                      "Beruflich: Gewerbe sonstiges",
                      "Privat: Spende",
                      "Privat: Versicherungen privat",
                      "Privat: Arztkosten/Medikamente",
                    ],
                  },
                  empfaenger: { type: "string" },
                },
                required: [
                  "date",
                  "betragMitSteuer",
                  "betragOhneSteuer",
                  "steuersatz",
                  "kategorie",
                  "empfaenger",
                  "beschreibung",
                ],
                additionalProperties: false,
              },
            },
          },
        });

        console.log(response2);
        const data = response2.output[0].content[0].text;
        console.log(data);
        const jsonData = JSON.parse(data);
        console.log(jsonData);
        Object.assign(item, jsonData); // Werte aktualisieren, ohne die Beschreibung zu verändern
      } catch (error) {
        console.error("Fehler bei der Analyse:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.loader {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  height: 10px;
  margin-top: 0;
  z-index: 1000;
}
</style>
