<template>
  <v-container fluid>
    <v-toolbar dense flat>
      <v-row no-gutters>
        <v-col cols="2">
          <v-btn
            icon
            @click="
              (showWebseite = true) &&
                (neueWebseite = true) &&
                (selectedWebseite = {})
            "
            ><v-icon>add</v-icon></v-btn
          >
        </v-col>
        <v-col cols="2" offset="8" class="text-right">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Suchen"
            single-line
            solo
            flat
            hide-details
            clearable
            clear-icon="close"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-divider></v-divider>

    <v-row class="mt-1">
      <v-col cols="12" md="10" offset-md="1">
        <v-card outlined>
          <v-data-table
            dense
            :headers="headers"
            :items="webseiten"
            :search="search"
            height="calc(100vh - 210px)"
            fixed-header
            hide-default-footer
            :items-per-page="-1"
            :hide-default-header="$vuetify.breakpoint.xsOnly"
            no-data-text="Keine Webseiten vorhanden"
          >
            <template v-slot:item="{ item, index }">
              <tr
                v-on:dblclick="
                  (showWebseite = true) &&
                    (selectedWebseite = item) &&
                    (neueWebseite = false)
                "
              >
                <td style="width: 20px">{{ index + 1 }}.</td>
                <td>{{ item.name }}</td>
                <td>{{ item.url }}</td>
                <td>{{ item.created | date("DDMMYYYY") }}</td>
                <td>{{ item.kunde | user(kunden) }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showWebseite"
      width="80%"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="primary white--text">
          {{
            neueWebseite
              ? "Neue Webseite anlegen"
              : "Webseite bearbeiten | " + selectedWebseite.name
          }}
          <v-spacer></v-spacer>
          <v-btn icon class="white--text" @click="showWebseite = false"
            ><v-icon>close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          <v-row v-if="selectedWebseite">
            <v-col cols="12" md="6"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedWebseite.name"
                label="Name"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="6"
              ><v-autocomplete
                dense
                outlined
                hide-details
                :items="kundenSelect"
                v-model="selectedWebseite.kunde"
                label="Kunde"
              ></v-autocomplete
            ></v-col>
            <v-col cols="12" md="6"
              ><v-text-field
                dense
                outlined
                hide-details
                append-outer-icon="open_in_new"
                @click:append-outer="openLinkInNewTab(selectedWebseite.url)"
                v-model="selectedWebseite.url"
                label="URL"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="3"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedWebseite.benutzername"
                label="Benutzername"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="3"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedWebseite.passwort"
                label="Passwort"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="6"
              ><v-text-field
                dense
                outlined
                hide-details
                append-outer-icon="open_in_new"
                @click:append-outer="openLinkInNewTab(selectedWebseite.host)"
                v-model="selectedWebseite.host"
                label="Host"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="3"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedWebseite.hostBenutzername"
                label="Benutzername"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="3"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedWebseite.hostPasswort"
                label="Passwort"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="6">
              <v-switch
                dense
                outlined
                hide-details
                v-model="selectedWebseite.service"
                label="Halbjährlicher Service"
              ></v-switch>
            </v-col>
            <v-col cols="12" md="3"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedWebseite.nextService"
                label="Nächster Service"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="3"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedWebseite.created"
                label="Erstellungsdatum"
                append-outer-icon="refresh"
                @click:append-outer="getErstellungsdatum()"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="12"
              ><v-textarea
                dense
                outlined
                hide-details
                v-model="selectedWebseite.bemerkung"
                label="Bemerkung"
              ></v-textarea
            ></v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            v-if="!neueWebseite"
            class="red--text"
            icon
            @click="deleteWebseite(selectedWebseite.id)"
            ><v-icon>delete</v-icon></v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="showWebseite = false">Abbrechen</v-btn>
          <v-btn
            v-if="!neueWebseite"
            class="primary"
            @click="updateWebseite()"
            dark
            text
            >Update</v-btn
          >
          <v-btn v-else class="primary" @click="createWebseite()" dark text
            >Anlegen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      showWebseite: false,
      neueWebseite: false,
      selectedWebseite: {},
      headers: [
        { text: "", value: "" },
        { text: "Name", value: "name" },
        { text: "URL", value: "url" },
        { text: "Erstellt", value: "created" },
        { text: "Kunde", value: "kunde" },
      ],
    };
  },
  computed: {
    webseiten() {
      const webseiten = this.$store.state.webseiten.webseiten;
      return webseiten.sort((a, b) => (a.created < b.created ? 1 : -1));
    },
    kunden() {
      return this.$store.state.kunden.kunden;
    },
    kundenSelect() {
      let kunden = [];
      this.$store.state.kunden.kunden.forEach((kunde) => {
        kunden.push({
          value: kunde.id,
          text:
            kunde.firma + " (" + kunde.nachname + ", " + kunde.vorname + ")",
        });
      });
      return kunden;
    },
  },
  methods: {
    createWebseite() {
      this.$store.dispatch("webseiten/createWebseite", this.selectedWebseite);
      this.showWebseite = false;
      this.selectedWebseite = {};
    },
    getErstellungsdatum() {
      this.selectedKunde.created = new Date().toISOString().substr(0, 10);
    },
    updateWebseite() {
      this.$store.dispatch(
        "webseiten/updateWebseiteData",
        this.selectedWebseite
      );
      this.showWebseite = false;
      this.selectedWebseite = {};
    },
    deleteWebseite(id) {
      this.$store.dispatch("webseiten/deleteWebseiteData", id);
      this.showWebseite = false;
      this.selectedWebseite = {};
    },
    openLinkInNewTab(url) {
      if (url !== undefined) {
        window.open(url, "_blank");
      }
    },
  },
};
</script>

<style>
.loader {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  height: 10px;
  margin-top: 0;
  z-index: 1000;
}
</style>
