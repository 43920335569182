import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// initial state
const state = {
  positionen: [],
  loading: false,
  error: null,
};

// getters
const getters = {
  position: (state) => (id) => {
    return state.positionen.find((position) => position.id === id);
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  loadPositionen({ commit }) {
    commit("setLoading", true);
    console.log("Positionen laden...");
    firebase
      .firestore()
      .collection("positionen")
      .orderBy("number", "desc")
      .onSnapshot(
        (snapshot) => {
          const positionen = [];
          snapshot.forEach((doc) => {
            positionen.push({
              ...doc.data(),
              id: doc.id,
            });
            commit("setLoadedPositionen", positionen);
            commit("setLoading", false);
          });
        },
        (error) => {
          console.log(error);
          commit("setLoading", false);
        }
      );
  },
  // eslint-disable-next-line
  createPosition({ commit, getters }, payload) {
    firebase
      .firestore()
      .collection("positionen")
      .add(payload)
      .catch((error) => {
        console.log(error);
      });
    // Reach out to firebase and store it
  },
  deletePositionData({ commit }, payload) {
    commit("setLoading", true);
    firebase
      .firestore()
      .collection("positionen")
      .doc(payload)
      .delete()
      .then(() => {
        commit("setLoading", false);
        commit("deletePosition", payload);
      })
      .catch((error) => {
        console.log(error);
        commit("setLoading", false);
      });
    commit("setLoading", false);
  },
  updatePositionData({ commit }, payload) {
    commit("setLoading", true);
    const updateObj = {};
    Object.keys(payload).forEach((key) => {
      if (payload[key] !== undefined) {
        updateObj[key] = payload[key];
      }
    });
    firebase
      .firestore()
      .collection("positionen")
      .doc(payload.id)
      .update(updateObj)
      .then(() => {
        commit("setLoading", false);
        commit("updatePosition", payload);
      })
      .catch((error) => {
        console.log(error);
        commit("setLoading", false);
      });
  },
};

// mutations
const mutations = {
  setLoadedPositionen(state, payload) {
    state.positionen = payload;
  },
  createPosition(state, payload) {
    state.positionen.push(payload);
  },
  updatePosition(state, payload) {
    const position = state.positionen.find((position) => {
      return position.id === payload.id;
    });
    if (payload.creatorID) {
      position.creatorID = payload.creatorID;
    }
    if (payload.date) {
      position.date = payload.date;
    }
    if (payload.bezahlt) {
      position.bezahlt = payload.bezahlt;
    }
    if (payload.fahrer) {
      position.fahrer = payload.fahrer;
    }
    if (payload.km) {
      position.km = payload.km;
    }
    if (payload.title) {
      position.title = payload.title;
    }
  },
  deletePosition(state, payload) {
    let array = state.positionen;
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === payload) {
        array.splice(i, 1);
      }
    }
    state.positionen = array;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  clearError(state) {
    state.error = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
