<template>
  <v-container fluid>
    <v-toolbar dense flat>
      <v-row no-gutters>
        <v-col cols="4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="neueAngebotErstellen()"
                ><v-icon>add</v-icon></v-btn
              >
            </template>
            <span>Neues Angebot</span>
          </v-tooltip>
        </v-col>
        <v-col cols="8" md="2" offset-md="6" class="text-right">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Suchen"
            single-line
            solo
            flat
            hide-details
            clearable
            clear-icon="close"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-divider></v-divider>

    <v-row class="mt-1">
      <v-col cols="12" md="10" offset-md="1">
        <v-card outlined>
          <v-data-table
            :headers="headers"
            :items="angebote"
            :search="search"
            dense
            height="calc(100vh - 220px)"
            fixed-header
            hide-default-footer
            :items-per-page="-1"
            :hide-default-header="$vuetify.breakpoint.xsOnly"
            no-data-text="Keine Angebote vorhanden"
          >
            <template v-slot:header="">
              <thead>
                <tr class="hidden-sm-and-up">
                  <th>Nr</th>
                  <th>Kunde</th>
                  <th>Betrag</th>
                  <th>Status</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr
                v-on:dblclick="
                  (showAngebot = true) &&
                    (selectedAngebot = item) &&
                    (neueAngebot = false)
                "
              >
                <td style="width: 100px">{{ item.angebotsnummer }}</td>
                <td class="hidden-xs-only" style="width: 130px">
                  {{ item.angebotsdatum | date("DDMMYYYY") }}
                </td>
                <td class="py-1">
                  <span class="font-weight-bold">{{
                    item.kunde | user(kunden)
                  }}</span>
                  <br />{{ item.betreff }}
                </td>
                <td style="width: 140px" class="text-right">
                  {{ item.summe | money }}
                  <span v-if="item.mwst" class="grey--text"
                    ><br />{{ (item.summe * 1.19) | money }}</span
                  >
                </td>
                <td v-if="item.status === 'Entwurf'">
                  <v-chip small color="grey" label>{{ item.status }}</v-chip>
                </td>
                <td v-if="item.status === 'Offen'">
                  <v-chip small color="blue" label>{{ item.status }}</v-chip>
                </td>
                <td v-if="item.status === 'Angenommen'">
                  <v-chip small color="green" label>{{ item.status }}</v-chip>
                </td>
                <td v-if="item.status === 'Abgeschlossen'">
                  <v-chip small color="primary" label>{{ item.status }}</v-chip>
                </td>
                <td v-if="item.status === 'Abgelehnt'">
                  <v-chip small color="red" label>{{ item.status }}</v-chip>
                </td>
                <td style="width: 20px" class="hidden-xs-only">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="printAngebot(item)"
                        ><v-icon>download</v-icon></v-btn
                      >
                    </template>
                    <span>Herunterladen</span>
                  </v-tooltip>
                </td>
                <td style="width: 20px" class="hidden-xs-only">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="duplicateAngebot(item)"
                        ><v-icon>content_copy</v-icon></v-btn
                      >
                    </template>
                    <span>Duplizieren</span>
                  </v-tooltip>
                </td>
                <td style="width: 20px" class="hidden-xs-only">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="createProjekt(item)"
                        ><v-icon>check</v-icon></v-btn
                      >
                    </template>
                    <span>In Projekt umwandeln</span>
                  </v-tooltip>
                </td>
                <td style="width: 20px" class="hidden-xs-only">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="createRechnung(item)"
                        ><v-icon>published_with_changes</v-icon></v-btn
                      >
                    </template>
                    <span>In Rechnung umwandeln</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showAngebot"
      width="80%"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span v-if="neueAngebot">Neue Angebot</span>
          <span v-else>Angebot bearbeiten</span>
          <v-spacer></v-spacer>
          <v-btn icon class="white--text" @click="showAngebot = false"
            ><v-icon>close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          <v-row v-if="selectedAngebot">
            <v-col cols="12" md="3"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedAngebot.betreff"
                label="Betreff"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="3"
              ><v-autocomplete
                dense
                outlined
                hide-details
                :items="kundenSelect"
                v-model="selectedAngebot.kunde"
                label="Kunde"
              ></v-autocomplete
            ></v-col>
            <v-col cols="12" md="3"
              ><v-autocomplete
                dense
                outlined
                hide-details
                :items="[
                  'Entwurf',
                  'Offen',
                  'Angenommen',
                  'Abgelehnt',
                  'Abgeschlossen',
                ]"
                v-model="selectedAngebot.status"
                label="Status"
              ></v-autocomplete
            ></v-col>
            <v-col cols="12" md="3"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedAngebot.angebotsnummer"
                label="Angebotsnummer"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="3"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedAngebot.angebotsdatum"
                label="Angebotsdatum"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="3"
              ><v-checkbox
                dense
                outlined
                hide-details
                v-model="selectedAngebot.mwst"
                label="MwSt"
              ></v-checkbox
            ></v-col>
            <v-col cols="12" md="12"
              ><v-textarea
                dense
                outlined
                hide-details
                auto-grow
                v-model="selectedAngebot.freitext"
                label="Freitext"
              ></v-textarea
            ></v-col>
          </v-row>
          <v-simple-table class="mt-4">
            <tbody>
              <tr
                v-for="(item, index) in selectedAngebot.positionen"
                :key="index"
                class="pa-2"
              >
                <td style="width: 20px">{{ index + 1 }}.</td>
                <td class="py-2">
                  <span class="subtitle-2"
                    ><v-text-field
                      dense
                      outlined
                      hide-details
                      v-model="item.titel"
                      label="Titel"
                    ></v-text-field
                  ></span>
                  <br />
                  <v-textarea
                    class="caption"
                    row-height="2"
                    dense
                    outlined
                    hide-details
                    v-model="item.beschreibung"
                    label="Beschreibung"
                    rows="3"
                    auto-grow
                  ></v-textarea>
                </td>
                <td style="width: 140px">
                  <v-text-field
                    class="my-1"
                    dense
                    outlined
                    hide-details
                    label="Anzahl"
                    v-model="item.anzahl"
                    placeholder="5.00"
                  ></v-text-field>
                  <v-text-field
                    class="my-1"
                    dense
                    outlined
                    hide-details
                    label="Preis"
                    v-model="item.einzelpreis"
                    placeholder="10.00"
                  ></v-text-field>
                  <v-text-field
                    class="my-1"
                    dense
                    filled
                    hide-details
                    readonly
                    :placeholder="(item.anzahl * item.einzelpreis) | money"
                  ></v-text-field>
                </td>
                <td style="width: 20px">
                  <v-btn icon @click="removeAngebotPosition(index)"
                    ><v-icon>close</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td style="text-align: right">Summe:</td>
                <td style="width: 140px">
                  <v-text-field
                    class="my-2"
                    dense
                    filled
                    hide-details
                    readonly
                    :placeholder="summe | money"
                  ></v-text-field>
                </td>
                <td>
                  <v-btn text block @click="addAngebotPosition()"
                    ><v-icon>add</v-icon></v-btn
                  >
                </td>
              </tr>
              <tr v-if="selectedAngebot.mwst">
                <td></td>
                <td style="text-align: right">MwSt:</td>
                <td style="width: 140px">
                  <v-text-field
                    class="my-2"
                    dense
                    filled
                    hide-details
                    readonly
                    :placeholder="(summe * 0.19) | money"
                  ></v-text-field>
                </td>
                <td></td>
              </tr>
              <tr v-if="selectedAngebot.mwst">
                <td></td>
                <td style="text-align: right">Gesamt:</td>
                <td style="width: 140px">
                  <v-text-field
                    class="my-2"
                    dense
                    filled
                    hide-details
                    readonly
                    :placeholder="(summe * 1.19) | money"
                  ></v-text-field>
                </td>
                <td></td>
              </tr>
            </tfoot>
          </v-simple-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            class="red--text"
            icon
            @click="deleteAngebot(selectedAngebot.id)"
            ><v-icon>delete</v-icon></v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="showAngebot = false">Abbrechen</v-btn>
          <v-btn
            class="primary"
            v-if="neueAngebot"
            @click="createAngebot()"
            dark
            text
            >Erstellen</v-btn
          >
          <v-btn class="primary" v-else @click="updateAngebot()" dark text
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import JSPDF from "jspdf";
export default {
  data() {
    return {
      search: "",
      showAngebot: false,
      neueAngebot: false,
      selectedAngebot: {},
      headers: [
        { text: "Nummer", value: "angebotsnummer" },
        { text: "Datum", value: "angebotsdatum" },
        { text: "Kunde", value: "kunde" },
        { text: "Betrag", value: "betrag" },
        { text: "Status", value: "status" },
        { text: "", value: "" },
        { text: "", value: "" },
        { text: "", value: "" },
        { text: "", value: "" },
      ],
    };
  },
  computed: {
    angebote() {
      const angebote = this.$store.state.angebote.angebote;
      angebote.sort((a, b) => {
        const statusOrder = {
          Entwurf: 1,
          Offen: 2,
          Angenommen: 3,
          Abgeschlossen: 4,
          Abgelehnt: 5,
        };
        return statusOrder[a.status] - statusOrder[b.status];
      });
      return angebote;
    },
    kunden() {
      return this.$store.state.kunden.kunden;
    },
    kundenSelect() {
      let kunden = [];
      this.$store.state.kunden.kunden.forEach((kunde) => {
        kunden.push({
          value: kunde.id,
          text: kunde.firma + "(" + kunde.nachname + ", " + kunde.vorname + ")",
        });
      });
      return kunden;
    },
    summe() {
      let summe = 0;
      if (this.selectedAngebot.positionen !== undefined) {
        this.selectedAngebot.positionen.forEach((position) => {
          summe +=
            parseFloat(position.anzahl) * parseFloat(position.einzelpreis);
        });
      }
      return summe;
    },
  },
  methods: {
    createAngebot() {
      this.selectedAngebot.summe = this.summe;
      this.$store.dispatch("angebote/createAngebot", this.selectedAngebot);
      this.showAngebot = false;
      this.selectedAngebot = {};
    },
    duplicateAngebot(item) {
      this.selectedAngebot = item;
      this.neueAngebot = true;
      this.showAngebot = true;
    },
    updateAngebot() {
      this.selectedAngebot.summe = this.summe;
      this.$store.dispatch("angebote/updateAngebotData", this.selectedAngebot);
      this.showAngebot = false;
      this.selectedAngebot = {};
    },
    deleteAngebot(id) {
      this.$store.dispatch("angebote/deleteAngebotData", id);
      this.showAngebot = false;
      this.selectedAngebot = {};
    },
    neueAngebotErstellen() {
      this.selectedAngebot = {};
      this.selectedAngebot.positionen = [];
      this.neueAngebot = true;
      this.showAngebot = true;
    },
    addAngebotPosition() {
      if (this.selectedAngebot.positionen === undefined) {
        this.selectedAngebot.positionen = [];
      }
      this.selectedAngebot.positionen.push({
        titel: "",
        beschreibung: "",
      });
    },
    removeAngebotPosition(index) {
      this.selectedAngebot.positionen.splice(index, 1);
    },
    createRechnung(item) {
      let rechnung = item;
      rechnung.status = "Entwurf";
      rechnung.rechnungsnummer =
        new Date().getFullYear() + "-" + item.angebotsnummer;
      this.$store.dispatch("rechnungen/createRechnung", rechnung);
    },
    createProjekt(item) {
      let projekt = item;
      projekt.budget = item.summe;
      projekt.status = "Offen";
      projekt.start = new Date().toISOString().substr(0, 10);
      projekt.stundenlohn = 1;
      projekt.titel = item.betreff;
      projekt.zeiten = [];
      this.$store.dispatch("projekte/createProjekt", projekt);
    },
    printAngebot(item) {
      let angebot = item;
      let kunde = this.$store.getters["kunden/kunde"](angebot.kunde);
      let doc = new JSPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
      });
      doc.setFont("Helvetica");
      // let img = new Image()
      // img.src = '/static/wappen.png'
      // doc.addImage(img, 115, 25, 90, 90)
      doc.setFontSize(11);
      // let lines1 = doc.splitTextToSize(block.veranstaltung.name, 44)
      // let lines2 = doc.splitTextToSize(block.veranstaltung.bemerkung, 44)
      // let lange = zeile + parseInt(parseInt(8) * lines1.length / 2) + parseInt(parseInt(8) * lines2.length / 2)
      // if (lange > 145) { (doc.addPage()) && (zeile = 11) }
      doc.setFont("Helvetica", "bold");
      doc.text("Benedikt Breuer", 21, 43);
      doc.setFont("Helvetica", "normal");
      doc.text(" | Rehfuesstraße 37 | 53115 Bonn", 52, 43);
      doc.text(kunde.firma, 21, 60);
      doc.text(kunde.anrede, 21, 65);
      doc.text(kunde.vorname + " " + kunde.nachname, 21, 70);
      doc.text(kunde.adresse, 21, 75);
      doc.text(kunde.plz + " " + kunde.ort, 21, 80);
      doc.setFont("Helvetica", "bold");
      doc.text("Angebotsnr.", 21, 100);
      doc.text("Kundennr.", 61, 100);
      doc.text("Datum", 101, 100);
      doc.setFont("Helvetica", "normal");
      doc.text(angebot.angebotsnummer, 21, 105);
      doc.text(kunde.kundennummer, 61, 105);
      doc.text(
        new Date(angebot.angebotsdatum).toLocaleString(["de-de"], {
          month: "short",
          day: "2-digit",
          year: "numeric",
        }),
        101,
        105
      );
      doc.setFont("Helvetica", "bold");
      doc.text(angebot.betreff, 21, 125);
      doc.setFont("Helvetica", "normal");
      let lines1 = doc.splitTextToSize(angebot.freitext, 168);
      doc.text(lines1, 21, 135);
      let zeile = 135 + parseInt((parseInt(11) * lines1.length) / 2);
      doc.setFont("Helvetica", "bold");
      doc.setFillColor(53, 64, 79);
      doc.rect(0, 265, 210, 297, "F");
      doc.setFontSize(9);
      doc.setTextColor(200, 200, 200);
      doc.setFont("Helvetica", "bold");
      doc.text("Benedikt Breuer", 21, 272);
      doc.setFont("Helvetica", "normal");
      doc.text("Rehfuesstraße 37", 21, 277);
      doc.text("53115 Bonn", 21, 282);
      doc.text("Steuernummer: 205/5037/3123", 21, 287);
      doc.text("Inhaber: Benedikt Breuer", 21, 292);
      doc.setFont("Helvetica", "bold");
      doc.text("Kontakt", 81, 272);
      doc.setFont("Helvetica", "normal");
      doc.text("+49 163 444 5098", 81, 277);
      doc.text("breuerbe@gmail.com", 81, 282);
      doc.text("benediktbreuer.de", 81, 287);
      doc.setFont("Helvetica", "bold");
      doc.text("Bankverbindung", 141, 272);
      doc.setFont("Helvetica", "normal");
      doc.text("IBAN: DE07 1001 1001 2740 0831 39", 141, 277);
      doc.text("BIC: NTSB DEB1 XXX", 141, 282);
      doc.addPage();
      doc.setFontSize(11);
      zeile = 25;
      doc.setTextColor(0, 0, 0);
      doc.setFont("Helvetica", "bold");
      zeile = parseInt(zeile) + 5;
      doc.text("Pos", 21, zeile);
      doc.text("Beschreibung", 31, zeile);
      doc.text("Menge", 140, zeile);
      doc.text("Preis", 170, zeile, { align: "right" });
      doc.text("Gesamt", 190, zeile, { align: "right" });
      doc.setFont("Helvetica", "normal");
      zeile = parseInt(zeile) + 2;
      doc.line(21, zeile, 189, zeile);
      zeile = parseInt(zeile) + 6;
      let pos = "1";
      let summe = 0;
      angebot.positionen.forEach((position) => {
        let linesBeschreibung = doc.splitTextToSize(position.beschreibung, 105);
        if (
          parseInt(zeile) +
            parseInt((parseInt(11) * linesBeschreibung.length) / 2) +
            10 >
          260
        ) {
          doc.setFont("Helvetica", "bold");
          doc.text(
            "Zwischensumme " +
              summe.toLocaleString("de-DE", { minimumFractionDigits: 2 }) +
              " €",
            189,
            zeile + 5,
            { align: "right" }
          );
          doc.setFillColor(53, 64, 79);
          doc.rect(0, 265, 210, 297, "F");
          doc.setFontSize(9);
          doc.setTextColor(200, 200, 200);
          doc.text("Benedikt Breuer", 21, 272);
          doc.setFont("Helvetica", "normal");
          doc.text("Rehfuesstraße 37", 21, 277);
          doc.text("53115 Bonn", 21, 282);
          doc.text("Steuernummer: 205/5037/3123", 21, 287);
          doc.text("Inhaber: Benedikt Breuer", 21, 292);
          doc.setFont("Helvetica", "bold");
          doc.text("Kontakt", 81, 272);
          doc.setFont("Helvetica", "normal");
          doc.text("+49 163 444 5098", 81, 277);
          doc.text("breuerbe@gmail.com", 81, 282);
          doc.text("benediktbreuer.de", 81, 287);
          doc.setFont("Helvetica", "bold");
          doc.text("Bankverbindung", 141, 272);
          doc.setFont("Helvetica", "normal");
          doc.text("IBAN: DE07 1001 1001 2740 0831 39", 141, 277);
          doc.text("BIC: NTSB DEB1 XXX", 141, 282);
          doc.addPage();
          doc.setFontSize(11);
          zeile = 25;
          doc.setTextColor(0, 0, 0);
          doc.setFont("Helvetica", "bold");
          zeile = parseInt(zeile) + 5;
          doc.text("Pos", 21, zeile);
          doc.text("Beschreibung", 31, zeile);
          doc.text("Menge", 140, zeile);
          doc.text("Preis", 170, zeile, { align: "right" });
          doc.text("Gesamt", 190, zeile, { align: "right" });
          doc.setFont("Helvetica", "normal");
          zeile = parseInt(zeile) + 2;
          doc.line(21, zeile, 189, zeile);
          zeile = parseInt(zeile) + 6;
        }
        doc.text(pos, 21, zeile);
        doc.text(position.titel, 31, zeile);
        doc.setTextColor(140, 140, 140);
        doc.text(linesBeschreibung, 31, zeile + 7);
        doc.setTextColor(0, 0, 0);
        doc.text(parseFloat(position.anzahl).toFixed(2), 140, zeile);
        doc.text(
          parseFloat(position.einzelpreis).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
          }) + " €",
          170,
          zeile,
          { align: "right" }
        );
        doc.text(
          (
            parseFloat(position.anzahl) * parseFloat(position.einzelpreis)
          ).toLocaleString("de-DE", { minimumFractionDigits: 2 }) + " €",
          190,
          zeile,
          { align: "right" }
        );
        summe =
          summe +
          parseFloat(position.anzahl) * parseFloat(position.einzelpreis);
        zeile = parseInt(zeile) + parseInt(5 * linesBeschreibung.length);
        zeile = parseInt(zeile) + 10;
        pos = String(parseInt(pos) + 1);
      });
      doc.line(21, zeile - 4, 189, zeile - 4);
      doc.setFont("Helvetica", "bold");
      zeile = parseInt(zeile) + 1;
      if (angebot.mwst) {
        doc.setFont("Helvetica", "normal");
        doc.text("Zwischensumme", 159, zeile, { align: "right" });
        doc.text(
          summe.toLocaleString("de-DE", { minimumFractionDigits: 2 }) + " €",
          189,
          zeile,
          { align: "right" }
        );
        zeile += 5;
        doc.text("Mehrwehrtsteuer (19%)", 159, zeile, { align: "right" });
        doc.text(
          (summe * 0.19).toLocaleString("de-DE", { minimumFractionDigits: 2 }) +
            " €",
          189,
          zeile,
          { align: "right" }
        );
        zeile += 3;
        doc.line(165, zeile, 189, zeile);
        zeile += 5;
        doc.setFont("Helvetica", "bold");
        doc.text("Summe", 159, zeile, { align: "right" });
        doc.text(
          (summe * 1.19).toLocaleString("de-DE", { minimumFractionDigits: 2 }) +
            " €",
          189,
          zeile,
          { align: "right" }
        );
        zeile += 1;
      } else {
        doc.text("Summe", 159, zeile, { align: "right" });
        doc.text(
          summe.toLocaleString("de-DE", { minimumFractionDigits: 2 }) + " €",
          189,
          zeile,
          { align: "right" }
        );
      }
      doc.setFont("Helvetica", "normal");
      zeile = parseInt(zeile) + 2;
      doc.line(165, zeile, 189, zeile);
      zeile = parseInt(zeile) + 1;
      doc.line(165, zeile, 189, zeile);
      if (!angebot.mwst) {
        doc.setFontSize(9);
        doc.text(
          "Gemäß § 19 UStG wird aufgrund der Kleinunternehmerregelung keine Umsatzsteuer erhoben.",
          21,
          258
        );
      }

      doc.setFillColor(53, 64, 79);
      doc.rect(0, 265, 210, 297, "F");
      doc.setFontSize(9);
      doc.setTextColor(200, 200, 200);
      doc.setFont("Helvetica", "bold");
      doc.text("Benedikt Breuer", 21, 272);
      doc.setFont("Helvetica", "normal");
      doc.text("Rehfuesstraße 37", 21, 277);
      doc.text("53115 Bonn", 21, 282);
      doc.text("Steuernummer: 205/5037/3123", 21, 287);
      doc.text("Inhaber: Benedikt Breuer", 21, 292);
      doc.setFont("Helvetica", "bold");
      doc.text("Kontakt", 81, 272);
      doc.setFont("Helvetica", "normal");
      doc.text("+49 163 444 5098", 81, 277);
      doc.text("breuerbe@gmail.com", 81, 282);
      doc.text("benediktbreuer.de", 81, 287);
      doc.setFont("Helvetica", "bold");
      doc.text("Bankverbindung", 141, 272);
      doc.setFont("Helvetica", "normal");
      doc.text("IBAN: DE07 1001 1001 2740 0831 39", 141, 277);
      doc.text("BIC: NTSB DEB1 XXX", 141, 282);

      doc.save(
        "Angebot-" +
          angebot.angebotsnummer +
          "-" +
          kunde.nachname +
          "-" +
          kunde.vorname +
          ".pdf"
      );
    },
  },
};
</script>

<style>
.loader {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  height: 10px;
  margin-top: 0;
  z-index: 1000;
}
</style>
